import UsersDB from '@/firebase/users-db'

/**
 * Create new user from firebase auth user infos
 */
// eslint-disable-next-line
export const createNewUserFromFirebaseAuthUser = async firebaseAuthUser => {
  const providerData = firebaseAuthUser.providerData[0]
  const { displayName, photoURL, email } = providerData
  const userDb = new UsersDB()
  let user = {
    displayName,
    photoURL,
    email,
    role: 'user'
  }

  if (window.localStorage.getItem('userPendingCreation')) {
    const {
      avatar,
      employeeID,
      firstname,
      lastname,
      location,
      notificationPreferences,
      phone,
      avatarImageName,
      avatarImageFormats
    } = JSON.parse(window.localStorage.getItem('userPendingCreation'))

    user = {
      ...user,
      avatar,
      employeeID,
      firstname,
      lastname,
      location,
      notificationPreferences,
      phone,
      avatarImageName,
      avatarImageFormats
    }

    window.localStorage.setItem('userPendingCreation', false)
  }

  console.log('👤 User create')
  return userDb.create(user, firebaseAuthUser.uid)
}
