<template>
  <aside class="aside-nav" :class="{ offline: !networkOnLine }">
    <router-link class="aside-nav-link" :to="{ name: 'tableau-de-bord' }">Tableau de bord</router-link>
    <hr />
    <router-link class="aside-nav-link" :to="{ name: 'admin/utilisateurs' }">Gestion des utilisateurs</router-link>
    <router-link class="aside-nav-link" :to="{ name: 'admin/succursales' }">Gestion des succursales</router-link>
    <router-link class="aside-nav-link" :to="{ name: 'admin/messages' }">Gestion des messages</router-link>
    <router-link class="aside-nav-link" :to="{ name: 'admin/questions' }">Gestion des questions</router-link>
    <hr />
    <router-link class="aside-nav-link" :to="{ name: 'connexion' }">Connexion</router-link>
  </aside>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.aside-nav {
  .aside-nav-link {
    display: block;
  }
}
</style>
