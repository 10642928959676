import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import Home from '@/views/Home'
import CheckLogin from '@/views/CheckLogin'
import { isNil } from 'lodash'
import store from '@/store'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    /*
      ==================
      Public Routes
      ==================
    */
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/check-login',
      name: 'check-login',
      component: CheckLogin,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/connexion',
      name: 'connexion',
      component: () => import(/* webpackChunkName: "client-chunk-login" */ '@/views/Login.vue'),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/mot-de-passe-oublie',
      name: 'mot-de-passe-oublie',
      component: () => import(/* webpackChunkName: "client-chunk-resetpassword" */ '@/views/ResetPassword.vue'),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/inscription',
      name: 'inscription',
      component: () => import(/* webpackChunkName: "client-chunk-register" */ '@/views/Register.vue'),
      meta: {
        authNotRequired: true
      }
    },

    /*
      ==================
      Auth Guarded Routes
      ==================
    */
    {
      path: '/tableau-de-bord',
      name: 'tableau-de-bord',
      component: () => import(/* webpackChunkName: "client-chunk-dashboard" */ '@/views/auth/Dashboard.vue')
    },

    /*
      ==================
      Admin Guarded Routes
      ==================
    */
    {
      path: '/admin/utilisateurs',
      name: 'admin/utilisateurs',
      component: () =>
        import(/* webpackChunkName: "client-chunk-userslist" */ '@/views/auth/admin/users/UsersList.vue'),
      meta: {
        authRequiresAdmin: true
      }
    },
    {
      path: '/admin/succursales',
      name: 'admin/succursales',
      component: () =>
        import(/* webpackChunkName: "client-chunk-locationslist" */ '@/views/auth/admin/locations/LocationsList.vue'),
      meta: {
        authRequiresAdmin: true
      }
    },
    {
      path: '/admin/questions',
      name: 'admin/questions',
      component: () =>
        import(/* webpackChunkName: "client-chunk-questionslist" */ '@/views/auth/admin/questions/QuestionsList.vue'),
      meta: {
        authRequiresAdmin: true
      }
    },
    {
      path: '/admin/messages',
      name: 'admin/messages',
      component: () =>
        import(/* webpackChunkName: "client-chunk-messageslist" */ '@/views/auth/admin/messages/MessagesList.vue'),
      meta: {
        authRequiresAdmin: true
      }
    },
    {
      path: '/admin/create/articles/:articleId?',
      name: 'admin/create/articles',
      component: () =>
        import(
          /* webpackChunkName: "admin-chunk-messagesarticlescreate" */ '@/views/auth/admin/messages/MessagesArticlesCreate.vue'
        ),
      meta: {
        authRequiresAdmin: true
      }
    },

    /*
      ==================
      Default
      ==================
    */
    { path: '*', redirect: '/login' }
  ]
})

/**
 * Handle user redirections
 */
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  if (!(to.meta && to.meta.authNotRequired) && isNil(store.state.authentication.user)) {
    const path = store.state.authentication.user === null ? '/connexion' : '/check-login'
    return next(`${path}?redirectUrl=${to.path}`)
  }
  if (to.meta && to.meta.authRequiresAdmin) {
    if (isNil(store.state.authentication.user)) {
      return next('/')
    }
    if (store.state.authentication.user.role !== 'admin') {
      return next('/')
    }
  }
  next()
})

export default router
