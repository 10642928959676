<template>
  <div id="app">
    <!-- Header / Nav -->
    <nav-bar></nav-bar>

    <!-- Aside / Nav -->
    <aside-nav></aside-nav>

    <!-- Main -->
    <div class="main-wrapper">
      <router-view />
    </div>

    <!-- Overlays -->
    <new-content-available-toastr
      v-if="newContentAvailable"
      class="new-content-available-toastr"
      :refreshing-app="refreshingApp"
      @refresh="serviceWorkerSkipWaiting"
    ></new-content-available-toastr>
    <apple-add-to-home-screen-modal
      v-if="showAddToHomeScreenModalForApple"
      class="apple-add-to-home-screen-modal"
      @close="closeAddToHomeScreenModalForApple(false)"
    >
    </apple-add-to-home-screen-modal>
  </div>
</template>
<script>
import NavBar from '@/components/NavBar'
import NewContentAvailableToastr from '@/components/NewContentAvailableToastr'
import AppleAddToHomeScreenModal from '@/components/AppleAddToHomeScreenModal'
import AsideNav from '@/components/layout/AsideNav'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: { NavBar, NewContentAvailableToastr, AppleAddToHomeScreenModal, AsideNav },
  computed: {
    ...mapGetters('app', ['newContentAvailable']),
    ...mapState('app', ['showAddToHomeScreenModalForApple', 'refreshingApp'])
  },
  methods: mapActions('app', ['closeAddToHomeScreenModalForApple', 'serviceWorkerSkipWaiting'])
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';
@import '@/theme/normalize.scss';
@import '@/theme/base.scss';
@import '@/theme/table.scss';
@import '@/theme/forms.scss';
@import '@/theme/typography.scss';
</style>
