import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/performance'
import 'firebase/analytics'
import 'firebase/storage'

// The configuration below is not sensitive data. You can serenely add your config here
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCYnnVwytn6XpMmwoZ22KJVIdH1vJTaolE',
  authDomain: 'videotron-pwa.firebaseapp.com',
  projectId: 'videotron-pwa',
  storageBucket: 'videotron-pwa.appspot.com',
  messagingSenderId: '508588336011',
  appId: '1:508588336011:web:57b20b176660b010e8c58e',
  measurementId: 'G-064MMP03WF'
}

firebase.initializeApp(firebaseConfig)

// Enable libs
const perf = firebase.performance()
const analytics = firebase.analytics()

// 🔥firebase utils
const auth = firebase.auth()
const storage = firebase.storage()
const storageRef = firebase.storage().ref()

// Configs
auth.languageCode = 'fr'

export { auth, perf, analytics, storage, storageRef, firebase }
