import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import SmartTable from 'vuejs-smart-table'

import App from './App.vue'
import router from './router'
import store from './store'
import '@/misc/register-service-worker'
import '@/misc/handle-network-status'
import '@/firebase/init'
import '@/firebase/authentication'
import '@/misc/handle-apple-install-prompt'
import 'pwacompat'

Vue.config.productionTip = false

Vue.use(VueSweetalert2)
Vue.use(SmartTable)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
