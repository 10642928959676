import router from '@/router'
import { isNil } from 'lodash'
import { createNewUserFromFirebaseAuthUser } from '@/misc/helpers'
import UsersDB from '@/firebase/users-db'
import { auth } from '@/firebase/init'

export default {
  /**
   * Callback fired when user login
   */
  login: async ({ commit }, firebaseAuthUser) => {
    console.log('👤 User read')

    const userFromFirebase = await new UsersDB().read(firebaseAuthUser.uid)

    let user = isNil(userFromFirebase) ? await createNewUserFromFirebaseAuthUser(firebaseAuthUser) : userFromFirebase

    user = {
      ...user,
      emailVerified: auth.currentUser.emailVerified
    }

    const date1 = user.nbSignInUpdatedAt.toDate()
    const date2 = new Date()
    const diffTime = Math.abs(date2 - date1)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1

    if (diffDays >= 1) {
      // Fresh New SignIn by user
      user = {
        ...user,
        nbSignIn: user.nbSignIn + 1,
        nbSignInUpdatedAt: new Date()
      }
    }

    await new UsersDB().update(user)

    console.log('👤 User right')

    commit('setUser', user)

    // dispatch('products/getUserProducts', null, { root: true })
  },

  /**
   * Callback fired when user logout
   */
  logout: ({ commit }) => {
    commit('setUser', null)
    // commit('products/setProducts', null, { root: true })

    const currentRouter = router.app.$route
    if (!(currentRouter.meta && currentRouter.meta.authNotRequired)) {
      router.push('/login')
    }
  }
}
