<template>
  <header class="navbar" :class="{ offline: !networkOnLine }">
    <a href="/"><img class="logo" src="@/assets/img/videotron-logo.svg" alt="Videotron"/></a>

    <div v-if="user" class="navbar-profile">
      <Avatar v-if="user" :user="user" />
      <p>{{ user.firstname }} {{ user.lastname }}</p>
      <a v-if="user" href="#" @click.prevent="logout">Déconnexion</a>
    </div>
  </header>
</template>

<script>
import firebase from 'firebase/app'
import { mapGetters, mapState } from 'vuex'
import Avatar from '@/components/particles/Avatar'

export default {
  components: { Avatar },
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle'])
  },
  methods: {
    async logout() {
      await firebase.auth().signOut()
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.navbar {
  position: relative;
  top: 0;
  left: 0;
  z-index: 20;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: auto;
  padding-right: 20px;

  background-color: $navbar-color;
  border-bottom: 1px solid rgba(#eaecef, 0.65);
  box-shadow: 0 4px 10px rgba(234, 236, 239, 1);

  .logo {
    display: block;
    width: auto;
    height: 100%;
    max-height: 55px;
  }

  .navbar-profile {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img,
    a {
      margin: 0 5px;
    }
  }
}
</style>
