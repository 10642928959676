<template>
  <img :src="avatarUrl" :alt="user.firstname" />
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
      default: null
    }
  },
  computed: {
    avatarUrl() {
      return this.user.avatarImageUrl ? this.user.avatarImageUrl : `/img/avatars/${this.user.avatar}.png`
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  display: block;
  width: auto;
  max-height: 55px;
}
</style>
