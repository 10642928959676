<template>
  <div class="page-wrapper">
    <div class="temp"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isNil } from 'lodash'

export default {
  data: () => ({ isLoading: true }),
  head() {
    return {
      title: {
        inner: 'Accueil'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} page d'accueil`,
          id: 'desc'
        }
      ]
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('app', ['appTitle'])
  },
  watch: {
    user: {
      handler(user) {
        if (!isNil(user)) {
          this.isLoading = true

          // const redirectUrl = isNil(this.$route.query.redirectUrl) ? '/tableau-de-bord' : this.$route.query.redirectUrl
          // this.$router.push(redirectUrl)
        } else {
          this.isLoading = true
          // this.$router.push('/connexion')
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.temp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  max-width: 745px;
  margin: 0 auto;
  height: 80vw;
  max-height: 745px;
  background: url('https://firebase.google.com/images/homepage/solutions-illo_1x.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid rgba(234, 236, 239, 0.25);
}
</style>
